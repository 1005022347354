<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="plan" :options="plans" optionLabel="nombre" placeholder="Seleccione Plan de Estudio ..." @change="Buscar()"/>
						<!--<Button label="Buscar" icon="pi pi-search" class="p-button-success" @click="Buscar()" />-->
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
				</Toolbar>

				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Asignaturas: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="sem" header="Semestre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sem}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="cod" header="Código" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="horas" header="Horas">
						<template #body="slotProps">
							HT{{slotProps.data.horat}} HP{{slotProps.data.horap}} Total:{{slotProps.data.total}}<br>
							{{slotProps.data.uc}}uc
						</template>
					</Column>
					<Column field="escala" header="Escala">
						<template #body="slotProps">
							{{slotProps.data.escala}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="bi bi-funnel" class="p-button-rounded p-button-info p-mr-2" @click="openPrelacion(slotProps.data)" v-if="edit" v-tooltip.bottom="'Prelación'"/>
							<Button icon="bi bi-arrow-left-right" class="p-button-rounded p-button-warning p-mr-2" @click="openEquivalente(slotProps.data)" v-if="edit" v-tooltip.bottom="'Convalidación'"/>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Asignatura '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-5">
								<label class="p-mb-3">Plan de Estudio</label>
								<Dropdown id="state" v-model="product.plan" :options="plans" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-5">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true" autofocus/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<label for="name">Código</label>
								<InputText id="name" v-model="product.cod" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">UC</label>
								<InputNumber id="uc" v-model="product.uc" required="true"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Escala</label>
								<Dropdown id="state" v-model="product.escala" :options="escalas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Semestre</label>
								<InputNumber id="sem" v-model="product.sem" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Semanas</label>
								<InputNumber id="semanas" v-model="product.semanas" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Requisito UC</label>
								<InputNumber id="req_uc" v-model="product.req_uc" required="true"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Tipo</label>
								<Dropdown id="tipo" v-model="product.tipo" :options="tipos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Orden</label>
								<InputNumber id="req_uc" v-model="product.orden" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Hor T</label>
								<InputNumber id="req_uc" v-model="product.horat" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Hor P</label>
								<InputNumber id="req_uc" v-model="product.horap" required="true"/>
							</div>
							<div class="p-col-2">
								<label for="name">Total Hor</label>
								<InputText id="cedula" :placeholder="(product.horap+product.horat)" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()"/>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true" :maximizable="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="prelacionDialog" :style="{width: size}" header="Prelaciones" :modal="true" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<h4>Asignatura: {{seleccion.nombre}}</h4>
							<div class="p-col-12">
									<h5>Carga de Prelaciones</h5>
									<PickList v-model="prelaciones" dataKey="code">
										<template #sourceHeader>
											Asignaturas Disponibles
										</template>
										<template #targetHeader>
											Prelaciones
										</template>
										<template #item="slotProps">
											<div>{{slotProps.item.nombre}}</div>
										</template>
									</PickList>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="savePrelacion()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="prelacionDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="equivalenteDialog" :style="{width: size}" header="Convalidación" :modal="true" :maximizable="true">
					<br>
                    <div class="p-field">
						<h4>Asignatura: {{seleccion.nombre}}</h4>
						<div class="p-grid">
							<div class="p-col-12">
								<div class="card">
									<h5>Asignaturas Disponibles</h5>
									<Tree :value="equivalentes" selectionMode="checkbox" v-model:selectionKeys="equivalente"></Tree>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveEquivalente()" />
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="equivalenteDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Academico','Asignaturas');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				prelacionDialog: false,
				equivalenteDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				seleccion: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				escalas: null,
				tipos: null,
				plan: null,
				plans: null,
				prelaciones: [],
				plan_equiv: null,
				asig_equiv: null,
				equivalente: [],
				equivalentes: [],				
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.plans=result.consult.plan;
					this.escalas=result.consult.escala;
					this.tipos=result.consult.tipo;
					if (this.$store.state.seleccion['caso'] == 'asignaturas') {
						this.plans.forEach(element => {
							if(this.$store.state.seleccion['id']==element.code){
								this.plan = element;
							}
						});  
						this.Buscar();
					} 
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if (this.plan != null) {
					Consulta.Procesar('Buscar',{
						id: 	this.plan.code,
					}).then(response => {
						//this.$store.state.error =response.result;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			openNew() {
				this.titulo = '(Nuevo)';
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			openPrelacion(product) {
				this.prelaciones = [];
				this.seleccion = {...product};
				Consulta.Procesar('Prelacion',{
					id: 	this.seleccion.id,
					plan: 	this.seleccion.plan_id
				}).then(response => {
					//this.$store.state.error =response;
					this.prelaciones = response.result.asignaturas;
					this.$store.commit('Loading');
				});
				this.prelacionDialog = true;
			},
			savePrelacion() {
				this.prelacionDialog = false;
				if (this.seleccion) {
					Consulta.Procesar('UPD2',{
						caso: 	'UPD2',
						id: 	this.seleccion.id,
						prelacion: 	this.prelaciones
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
					this.prelacionDialog = false;
					this.seleccion = {};
				}
			},
			openEquivalente(product) {
				this.seleccion = {...product};
				this.equivalente = [];
				this.equivalentes = [];
				Consulta.Procesar('Equivalente',{
					caso: 	'Equivalente',
					id: 	this.seleccion.id,
					plan: 	this.seleccion.plan_id
				}).then(response => {
					//this.$store.state.error = response.result;
					this.equivalentes = response.result.equivalentes;
					this.equivalente = response.result.equivalente;
					this.$store.commit('Loading');
				});
				this.equivalenteDialog = true;
			},
			saveEquivalente() {
				this.equivalenteDialog = false;
				if (this.seleccion) {
					Consulta.Procesar('UPD3',{
						caso: 	'UPD3',
						id: 	this.seleccion.id,
						equivalente: 	this.equivalente
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
					this.prelacionDialog = false;
					this.seleccion = {};
				}
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.plan == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione Plan de Estudio', life: 5000});
				} else if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.cod == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Código', life: 5000});
				} else if (this.product.uc < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca UC', life: 5000});
				} else if (this.product.escala == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Escala', life: 5000});
				} else if (this.product.sem < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca N° Semestre', life: 5000});
				} else if (this.product.semanas < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca N° Semanas', life: 5000});
				} else if (this.product.req_uc < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca N° Rquisitos UC', life: 5000});
				} else if (this.product.tipo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Tipo', life: 5000});
				} else if (this.product.horat < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca N° Horas Teóricas', life: 5000});
				} else if (this.product.horap < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca N° Horas Prácticas', life: 5000});
				} else {			
					this.productDialog = false;
					if (this.product.id) {
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							nombre: this.product.nombre,
							cod: this.product.cod,
							plan: this.product.plan.code,
							uc: this.product.uc,
							escala: this.product.escala.code,
							semanas: this.product.semanas,
							tipo: this.product.tipo.code,
							sem: this.product.sem,
							req_uc: this.product.req_uc,
							orden: this.product.orden,
							horat: this.product.horat,
							horap: this.product.horap,
							activo: this.product.activo
						}).then(response => {
							this.$store.commit('Loading');
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('INS',{
							id: 	this.product.id,
							nombre: this.product.nombre,
							cod: this.product.cod,
							plan: this.product.plan.code,
							uc: this.product.uc,
							escala: this.product.escala.code,
							semanas: this.product.semanas,
							tipo: this.product.tipo.code,
							sem: this.product.sem,
							req_uc: this.product.req_uc,
							orden: this.product.orden,
							horat: this.product.horat,
							horap: this.product.horap,
							activo: this.product.activo
						}).then(response => {
							this.$store.commit('Loading');
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}

					this.productDialog = false;
					this.seleccion = {};
				}
			},
			editProduct(product) {
				this.titulo = '(Editar)';
				this.product = {...product};
				this.escalas.forEach(element => {
					if(this.product.escala_id==element.code){
						this.product.escala = element;
					}
				});  
				this.plans.forEach(element => {
					if(this.product.plan_id==element.code){
						this.product.plan = element;
					}
				});  
				this.tipos.forEach(element => {
					if(this.product.tipo_id==element.code){
						this.product.tipo = element;
					}
				});  
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
